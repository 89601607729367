<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      show-date
      :date-selected="false"
      :date-validation="false"
      :filter-names="['search', 'make', 'model']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:registration_no="{ item }">
        <td class="">
          <div
            v-if="$admin.hasAccessTo('single-vehicle-trips.list')"
            class="imei-column"
          >
            <!-- @click="goToListingPage(item)" -->
            <!-- :to="{
                name: 'trip-listing',
                params: { imei: item.iot_imei },
                query: { vehicle_id: item.vehicle_id },
              }" -->
            <router-link :to="goToListingPage(item)">
              {{ item.registration_no }}
            </router-link>
          </div>
          <div v-else>
            {{ item.registration_no }}
          </div>
        </td>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card>
          <v-text-field
            v-model="options.search"
            dense
            clearable
            :label="$t('select')"
            @change="fetchData"
          />
        </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
          flat
          class="action-toolbar"
        > -->

        <refresh-button
          v-if="list?.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      headers: [
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "registration_no",
          width: "10%",
        },
        {
          text: this.$t("customer_name"),
          align: "left",
          sortable: false,
          con: true,
          value: "customer_name",
          width: "10%",
        },

        {
          text: this.$t("make_and_model"),
          align: "left",
          sortable: false,
          value: "make_and_model",
          width: "10%",
        },
        {
          text: this.$t("total_trip_count"),
          align: "left",
          sortable: false,
          value: "total_trip_count",
          width: "10%",
        },
        {
          text: this.$t("last_trip_recevied_at"),
          align: "left",
          sortable: false,
          value: "last_trip_received_at",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "trips/getLoading",
      meta: "trips/getMeta",
      list: "trips/getList",
    }),
    _headers() {
      const h = [];

      this.headers.forEach((el) => {
        if (el.con) {
          if (this.options.product == "oem") {
            el.text = this.$t("customer_name");
            el.value = "customer_name";
          } else if (this.options.product == "fleet") {
            el.text = this.$t("driver_name");
            el.value = "driver_name";
          }
        }
        h.push(el);
      });

      return h;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    // const params = {
    //   product: this.options.product,
    //   fleetId: this.options.fleetId ?? undefined,
    //   oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
    //   tenant_id: this.options.tenant_id,
    //   search: this.options.search ?? undefined,
    //   // vehicle_id: row.vehicle_id,
    //   page: this.options.page,
    //   perPage: this.options.itemsPerPage,
    // };
    const params = {
      name: "trips",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("trips/list", this.options).then(() => {
        // if (this.options.page > (this.customerVehiclesMeta?.lastPage || 1)) {
        //   this.options.page = 1;
        // }
      });
    },
    goToListingPage(row) {
      const params = {
        product: this.options.product,
        fleetId: this.options.fleetId ?? undefined,
        oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
        tenant_id: this.options.tenant_id,
        search: this.options.search ?? undefined,
        vehicle_id: row.vehicle_id,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        md_make_id: this.options.md_make_id,
        md_model_id: this.options.md_model_id,
      };
      // return this.$router.push({
      //   name: "trip-listing",
      //   params: { imei: row.iot_imei },
      //   query: params,
      // });
      return {
        name: "trip-listing",
        params: { imei: row.iot_imei },
        query: params,
      };
    },
  },
};
</script>
